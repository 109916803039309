//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import cash from '@modules/cash/mixins/cash';
import CashWrapper from "./CashWrapper";
import detect from '@/helpers/detects';
export default {
  name: 'DepositFailed',
  mixins: [cash],
  components: {
    CashWrapper: CashWrapper
  },
  computed: {
    alertInfo: function alertInfo() {
      return this.$store.getters['cash/alertInfo'];
    },
    isActiveApplePay: function isActiveApplePay() {
      return this.$store.getters['cash/isActiveApplePay'];
    },
    isMacOs: function isMacOs() {
      return detect.isMacOs();
    },
    isShowSupport: function isShowSupport() {
      var _this$$store$getters$;

      return ((_this$$store$getters$ = this.$store.getters['cash/alertInfo']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.isShowSupport) || this.isAnotherText || this.isPNA;
    },
    isCodeC2: function isCodeC2() {
      var _this$alertInfo, _this$alertInfo$code;

      return ((_this$alertInfo = this.alertInfo) === null || _this$alertInfo === void 0 ? void 0 : (_this$alertInfo$code = _this$alertInfo.code) === null || _this$alertInfo$code === void 0 ? void 0 : _this$alertInfo$code.toLowerCase()) === 'c2';
    },
    isPNA: function isPNA() {
      var _this$alertInfo2, _this$alertInfo2$code;

      return ((_this$alertInfo2 = this.alertInfo) === null || _this$alertInfo2 === void 0 ? void 0 : (_this$alertInfo2$code = _this$alertInfo2.code) === null || _this$alertInfo2$code === void 0 ? void 0 : _this$alertInfo2$code.toLowerCase()) === 'pna';
    },
    isAnotherText: function isAnotherText() {
      var _this$alertInfo3, _this$alertInfo4, _this$alertInfo4$code;

      return ((_this$alertInfo3 = this.alertInfo) === null || _this$alertInfo3 === void 0 ? void 0 : _this$alertInfo3.code) === '51' || ((_this$alertInfo4 = this.alertInfo) === null || _this$alertInfo4 === void 0 ? void 0 : (_this$alertInfo4$code = _this$alertInfo4.code) === null || _this$alertInfo4$code === void 0 ? void 0 : _this$alertInfo4$code.toLowerCase()) === 'fpp';
    },
    btnText: function btnText() {
      return this.isPNA ? 'PROCEED' : 'TRY AGAIN';
    },
    lastPresetId: function lastPresetId() {
      return this.$store.getters['cash/lastPresetId'];
    }
  },
  methods: {
    close: function close() {
      this.modalCommandClose();
    },
    tryAgain: function tryAgain() {
      var _this$alertInfo5;

      if (((_this$alertInfo5 = this.alertInfo) === null || _this$alertInfo5 === void 0 ? void 0 : _this$alertInfo5.type) === 'redeem') this.$router.push('/cash/redeem/');else this.$router.push('/cash/deposit-by-money/');
    },
    openSupport: function openSupport() {
      window.$store.$support.open();
    },
    goToMethods: function goToMethods() {
      this.$router.push("/cash/deposit-by-money/".concat(this.lastPresetId, "/"));
    },
    handleClickBtn: function handleClickBtn() {
      if (this.isPNA) this.goToMethods();else this.tryAgain();
    }
  },
  mounted: function mounted() {
    this.$store.dispatch('deposit/resetCashData');
    this.$store.dispatch('deposit/setIsShowBackBtn', false);
  }
};